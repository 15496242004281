<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Jurnal Adjustment : {{TransactionNumber}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                     <CRow>
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold required">Regional</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <v-select id="Region" class="pb-3" :options="RegionData" v-model="Region" />
                                <label id="errorRegion" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div>

                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Tgl. Transaksi</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <date-picker
                                    v-model="TransactionDate"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Select date"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                ></date-picker>
                                <label id="errorTransactionDate" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div>

                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">No. Transaksi</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <CInput id="TransactionNumber" v-model="TransactionNumber" class="font-weight-bold"/>
                                <label id="errorTransactionNumber" class="form-error" style="display: none; color: red;"></label>
                            </div>
                        </CRow>

                        <CRow>
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Kontak</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <v-select id="Contact" class="pb-3" :options="ContactData" v-model="Contact" />
                                <label id="errorContact" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div>
                            
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Deskripsi</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <CInput id="Desc" v-model="Desc" class="font-weight-bold" readonly/>
                                <label id="errorDesc" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div>

                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Status</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                            </div>
                        </CRow>

                        <CRow>
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Catatan</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </div>
                            <div class="col-sm-content">
                                <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                            </div>
                        </CRow>

                        <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Detail Jurnal Umum</h4></label>
                            <hr>
                            <datasource ref="detailDataSource" :data="this.DetailGridData" :schema-model-fields="this.DetailSchemaModel" 
                                :aggregate="[
                                    { field: 'debit', aggregate: 'sum' },
                                    { field: 'credit', aggregate: 'sum' },
                                    { field: 'foreign_currency_debit', aggregate: 'sum' },
                                    { field: 'foreign_currency_credit', aggregate: 'sum' }
                                ]"
                            />

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'detailDataSource'"
                                        :editable="{createAt: 'bottom'}"
                                        :filterable="true"
                                        :sortable="true"
                                        :scrollable="true"
                                        :toolbar="['create']"
                                        :cellClose="cellClose"
                                        :resizable="true"
                                        >

                                <kendo-grid-column  :field="'chart_of_account_id'"
                                                    :title="'Akun'"
                                                    :width="200"
                                                    :editor= "COADropDownEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'chart_of_account_name'"
                                                    :title="'Nama Akun'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'currency'"
                                                    :title="'Currency'"
                                                    :width="200"
                                                    :editor= "currencyDropDownEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'debit'"
                                                    :title="'Debit'"
                                                    :width="200"
                                                    :format="'{0:N2}'"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'>Rp. #=  kendo.toString(sum, 'n2') #</div>`"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'credit'"
                                                    :title="'Kredit'"
                                                    :width="200"
                                                    :format="'{0:N2}'"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'>Rp. #=  kendo.toString(sum, 'n2') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'exchange_rate'"
                                                    :title="'Kurs'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'foreign_currency_debit'"
                                                    :title="'Debit Valas'"
                                                    :width="200"
                                                    :format="'{0:N2}'"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'>Rp. #=  kendo.toString(sum, 'n2') #</div>`"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'foreign_currency_credit'"
                                                    :title="'Kredit Valas'"
                                                    :width="200"
                                                    :format="'{0:N2}'"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'>Rp. #=  kendo.toString(sum, 'n2') #</div>`"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'division'"
                                                    :title="'Divisi'"
                                                    :width="200"
                                                    :editor= "DivisionDropDownEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                        
                                <kendo-grid-column  :field="'notes'"
                                                    :title="'Catatan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'budgeting'"
                                                    :title="'Budgeting'"
                                                    :width="200"
                                                    :editor= "BudgetingDropDownEditor"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'sub_project'"
                                                    :title="'Sub Proyek'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                
                                <kendo-grid-column  :title="'&nbsp;'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :command="['destroy']"
                                                    :width="200"></kendo-grid-column>
                                
                            </kendo-grid>
                        </div>
                    </CRow>
                    <div v-if="this.View.toString() == 'false'" style="width:100%">
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/adjustment-journal')"> Close </CButton>
                    </div>
                </div>
                <adjustment-journal-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import adjustmentJournalServices from '../Script/AdjustmentJournalService.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import { sweet_alert } from '../../../../infrastructure/constant/variable';
import adjusmentJournalPDF from '../../../../assets/template/AdjustmentJournalPdfTemplate.vue';

export default {
    name: 'AdjustmentJournalPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'adjustment-journal-pdf': adjusmentJournalPDF
    },
    async mounted () {
        if(this.FormType == 'Add'){
            this.TransactionNumber = 'Auto';

            this.RegionData = await adjustmentJournalServices.getRegionQuery();
            this.Region = '';

            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.TransactionDate= dateNow;

            this.ContactData= await adjustmentJournalServices.getContactQuery();;
            this.Contact= null;

            this.Desc= 'Jurnal Adjustment';

            this.COAData= await adjustmentJournalServices.getCOAQuery();
            this.COA= '';
            this.CurrencyData = await adjustmentJournalServices.getCurrency();
            this.Currency= 'IDR';

            this.Note= '';

            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            this.DivisionData = await globalfunc.globalDropdown('ddl_finance_division');
            this.BudgetingData = await globalfunc.globalDropdown('ddl_finance_budgeting');

            this.SaveType = 'Add';
            this.View = false;
        }
        else{
            document.getElementById('TransactionNumber').readOnly = true;
            
            var data = await adjustmentJournalServices.getDetailJournal(this.Id);

            this.TransactionNumber = data.transaction_number;
            this.RegionData = await adjustmentJournalServices.getRegionQuery();
            this.Region = this.RegionData.find(c => c.value == data.region_id);

            this.TransactionDate= data.transaction_date;

            this.ContactData= await adjustmentJournalServices.getContactQuery();;
            this.Contact= this.ContactData.find(c => c.value == data.contact_id);
            this.Desc= data.desc;
            
            this.COAData= await adjustmentJournalServices.getCOAQuery();
            this.CurrencyData = await adjustmentJournalServices.getCurrency();

            this.Note= data.notes;
            this.StatusData = await globalfunc.globalDropdown('ddl_status_journal');
            this.Status = data.status;

            this.DivisionData = await globalfunc.globalDropdown('ddl_finance_division');
            this.BudgetingData = await globalfunc.globalDropdown('ddl_finance_budgeting');

            this.SaveType = 'Edit';

            //grid
            this.DetailGridData = globalfunc.objectToArrayConverter(data.journal_detail, 'Finance-JournalDetailSave');
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,

            //Detail
            TransactionNumber: '',

            RegionData: [],
            Region: '',

            TransactionDate: '',

            ContactData: [],
            Contact: null,

            Desc: '',

            COAData: [],
            CurrencyData: [],

            Debit: 0,
            Credit: 0,
            Note: '',

            StatusData: [],
            Status: '',

            ExchangeRate: 0,
            DivisionData: [],
            BudgetingData: [],

            //grid
            DetailGridData: [],
            DetailSchemaModel: {
                chart_of_account_id: { type: "string", editable: true },
                chart_of_account_name: { type: "string", editable: false },
                currency: { type: "string", editable: true },
                debit: { type: "number", editable: true },
                credit: { type: "number", editable: true },
                exchange_rate: { type: "number", editable: true, validation: {min:1} },
                foreign_currency_debit:  { type: "number", editable: false },
                foreign_currency_credit:  { type: "number", editable: false },
                division: { type: "string", editable: true,  },
                notes: { type: "string", editable: true },
                budgeting: { type: "string", editable: true },
                sub_project: { type: "string", editable: true },
            },
        }
    },
    methods: {
        cellClose(e){
            var grid = this.$refs.gridItem.kendoWidget();
            var dataItem = e.sender.dataItem($(e.container).parent());
            
            if(dataItem.currency == ""){
                dataItem.currency = "IDR";
            }

            if(dataItem.currency == "IDR"){
                dataItem.exchange_rate = 1;
            }

            if(dataItem.currency != "IDR" && dataItem.exchange_rate <= 0){
                this.$swal("Error", "Kurs tidak boleh lebih kecil dari 0", "error");
                dataItem.exchange_rate = 1;
            }

            var COAData = this.COAData.find(c => c.value == dataItem.chart_of_account_id);
            
            dataItem.chart_of_account_name = COAData.label.replace(' (', '').replace(')', '').replace(dataItem.chart_of_account_id, '');
            
            dataItem.foreign_currency_debit = dataItem.exchange_rate * dataItem.debit;
            dataItem.foreign_currency_credit = dataItem.exchange_rate * dataItem.credit;
            
            if(dataItem.division == "" || dataItem.division == null){
                dataItem.division = "-";
            }
            if(dataItem.budgeting == "" || dataItem.budgeting == null){
                dataItem.budgeting = "-";
            }
            
            this.DetailGridData = globalfunc.objectToArrayConverter(grid.dataSource._data, 'Finance-JournalDetail');
        },
        COADropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.COAData
                    }
                });
        },
        currencyDropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.CurrencyData
                    },
                });
        },
        async DivisionDropDownEditor(container, options){
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.DivisionData
                    },
                });
        },
        async BudgetingDropDownEditor(container, options){
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.BudgetingData
                    },
                });
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.Region == '' || this.Region == null){
                this.errorShow('errorRegion');
            }
            if(this.TransactionDate == '' || this.TransactionDate == null){
                this.errorShow('errorTransactionDate');
            }
            if(this.DetailGridData.length == 0){
                this.$swal("Error", "Detail bank masuk harus diisi terlebih dahulu", "error");
                this.Error++;
            }
        },
        async saveClick(){
            this.inputValidation();
            
            if(this.Error == 0){
                var contact = this.Contact == null ? null : this.Contact.value;

                var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
                var newData  = globalfunc.objectToArrayConverter(gridData, 'Finance-JournalDetailSave');

                const journalData = {
                    status: this.Status,
                    region_id: this.Region.value,
                    transaction_date: this.TransactionDate,
                    transaction_number: this.TransactionNumber,
                    contact_id: contact,
                    desc: this.Desc,
                    notes: this.Note,
                    new_journal_detail: newData
                };

                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : journalData
                    }
                    
                    adjustmentJournalServices.addJournal(variables).then(res => {
                        this.$loading(false);
                        var id = res.data.CreateJournalAdjustment.data.id;

                        this.$swal(sweet_alert.success_save_print).then((result) => {
                            if (result.isConfirmed == true) {
                                this.$refs.pdf.generatePDF(id);
                                this.View = true;
                            }else if (result.isDenied) {
                                const routeData = this.$router.resolve({name: 'Adjustment Journal Html Template', params: { id:id }});
                                window.open(routeData.href, '_blank');
                                this.View = true;
                            }else{
                                this.$router.push({ path: '/adjustment-journal' });
                            }
                        });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : parseInt(this.Id),
                        data : journalData
                    }
                    
                    adjustmentJournalServices.editJournal(variables).then(res => {
                        this.$loading(false);
                        
                        this.$swal(sweet_alert.update_save_print).then((result) => {
                            if (result.isConfirmed == true) {
                                this.$refs.pdf.generatePDF(variables.id);
                                this.View = true;
                            }else if (result.isDenied) {
                                const routeData = this.$router.resolve({name: 'Adjustment Journal Html Template', params: { id:variables.id }});
                                window.open(routeData.href, '_blank');
                                this.View = true;
                            }else{
                                this.$router.push({ path: '/adjustment-journal' });
                            }
                        });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>