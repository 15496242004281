import { render, staticRenderFns } from "./BankInStatusForm.vue?vue&type=template&id=23be3d9e&scoped=true"
import script from "./BankInStatusForm.vue?vue&type=script&lang=js"
export * from "./BankInStatusForm.vue?vue&type=script&lang=js"
import style0 from "./BankInStatusForm.vue?vue&type=style&index=0&id=23be3d9e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23be3d9e",
  null
  
)

export default component.exports