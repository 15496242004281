<template>
    <div>
        <div class="modal fade" id="BankInCreateModal" tabindex="-1" aria-labelledby="BankInCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="BankInCreateModalLabel" class="font-weight-bold">Add Bank In</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-10">
                                <div v-if="isHidden">
                                    <div class="rounded" style="height: auto; text-align: center; justify-content: center; border-color: red; border-style: solid; border-width: thick;">
                                        <h4 style="color: red;">Hidden Transaction Invoice Payment</h4>
                                    </div>
                                </div>
                                <div v-if="!isHidden">
                                    <div class="rounded" style="height: auto;  text-align: center; justify-content: center; border-color: #0000FF; border-style: solid; border-width: thick;">
                                        <h4 style="color: #0000FF;">Transaction Invoice Payment</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div v-if="isHidden">
                                    <button id="show" class="btn btn-primary" style="width: 100%;  float: right;" v-on:click="isHidden = false" @click="datasourceReload()">
                                        <h6>Show Data</h6>
                                    </button>
                                </div>
                                <div v-if="!isHidden">
                                    <button id="hide" class="btn btn-danger" style="width: 100%; float: right;" v-on:click="isHidden = true" @click="datasourceReload()">
                                        <h6>Hidden Data</h6>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :columns="columns"
                            :resizable="true"
                        >
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import bankInService from '../Script/BankInService';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import { response } from '../../../../infrastructure/constant/response';
import { globalfunc } from '../../../../shared/GlobalFunction.js';

export default {
    name: 'BankInCreateForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    mounted:  function () {
        var createDetailClick = this.createDetailClick;
        var updateJournalable = this.updateJournalable;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#CreateDetailButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            createDetailClick(dataItem.inv_payment_id);
        })

        GridElement.on("click", "#HideButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            updateJournalable(dataItem, "Hide");
        })

        GridElement.on("click", "#ShowButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            updateJournalable(dataItem, "Show");
        })

        this.datasourceReload();
    },
    data: function () {
        return {
            dataSource: [],
            columns: [
                { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; vertical-align:middle; font-weight:bold;" }, attributes: { "class": "k-text-center" }, template:this.columnButton},
                { field: "inv_payment_number", title: "No. Bukti", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "contact_name ", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "inv_payment_date", title: "Tanggal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(inv_payment_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "total", title: "Total Invoice Payment", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes:{style: "text-align:right;"}, format:"{0:N2}" },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            isHidden : false,
        }
    },
    methods: {
        columnButton(e){
            var customButton = "";
            var isHidden = e.hide_journalable == null ? false : e.hide_journalable;
            if(isHidden){
                customButton = `<div class="btn-group"> 
                                <button type="button" class="btn btn-success btn-md rounded" id="CreateDetailButton"> Create </button> &nbsp;&nbsp;
                                <button type="button" class="btn btn-primary btn-md rounded" id="ShowButton"> Show </button> 
                            </div>`;
            } else {
                customButton = `<div class="btn-group"> 
                                <button type="button" class="btn btn-success btn-md rounded" id="CreateDetailButton"> Create </button> &nbsp;&nbsp;
                                <button type="button" class="btn btn-danger btn-md rounded" id="HideButton"> Hide </button> 
                            </div>`;
            }

            return customButton;
        },
        addClick(){
            this.datasourceReload();
            window.$('#BankInCreateModal').modal('show');
        },
        createDetailClick(data){
            window.$('#BankInCreateModal').modal('hide');
            this.$router.push({ name: 'Bank In Form', params: {  formtype:'Add', id:data, view:'new', type:'InvoicePayment' } })
        },
        async updateJournalable(data, type){
            var status = false;

            if(type == "Hide"){
                status = true;
            }

            const variables = {
                invPaymentID : parseInt(data.inv_payment_id),
                type : 5,
                hidden : status
            }

            bankInService.editJournalable(variables).then(res => {
                this.$swal("Info", response.successUpdate, "success").then((result) => {
                    this.datasourceReload();
                });
            }).catch(error => {
                var errorMessage = globalfunc.ErrorExtractor(error)
                this.$swal("Error", errorMessage, "error");
            });
        },
        datasourceReload(){
            var isHidden = this.isHidden;
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_form_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                type : 5,
                                hidden : isHidden
                            }
                            return { 
                                query: bankInService.getJournalableQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetJournalable == null){
                            return [];
                        }else{
                            return response.data.GetJournalable;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetJournalable == null){
                            return 0;
                        }else{
                            return response.data.GetJournalable.length;
                        }
                    },
                    model: {
                        fields: {
                            inv_payment_date: {type:"date"},
                            total: {type:"number"},
                        }
                    }
                }
            });
        }
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>